/* eslint-disable react/require-default-props */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { isValidNumber } from 'libphonenumber-js';
import { Provider, useSelector } from 'react-redux';

import store from '../../store/store';

// Component Imports
import FormField from '../Forms/FormField';
import OptOut from '../Forms/OptOut';

import { pushData } from '../../ga/form_ga_data';

import { selectedOfferId, geotargetlyInit } from '../Deal/helpers';

const EnquireNow = ({
  postURL,
  channelID,
  isPersonal,
  geotargetly,
  pricing,
  personal_sources,
  business_sources,
  featured_deal,
  offer_id,
  derivative_id,
  initial_payment_months,
  term,
  mileage,
  salesAdmin,
}) => {
  // React Redux hooks
  const query = useSelector((state) => state.Deal);
  const offerId = offer_id || selectedOfferId(query);

  const derivativeId = derivative_id || query.derivativeId;
  const selectedTerm = term || query.contractLength;
  const selectedMileage = mileage || (query.annualMileage > 0 ? query.annualMileage : 5000);
  const { maintenanceSelected } = query;
  const initialPaymentMonths = initial_payment_months || query.initialMonths;
  const selectedOptionIds = query.selected.actuallySelectedOptionIds;

  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    businessName: '',
    email: '',
    telephone: '',
    source: '',
    disposal: '',
    soon: '',
    time: '',
    comments: '',
    timeTrading: '',
    businessType: '',
    fleetSize: '',
    mailList: false,
    authenticity_token: '',
    disabled: false,
    leadType: '',
  });

  useEffect(() => {
    if (geotargetly) {
      geotargetlyInit(document, 'script');
    }
  }, []);

  const { register, handleSubmit, errors, getValues } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const formRef = useRef(null);

  const handleFormSubmission = () => {
    if (state.disabled) {
      return;
    }

    setState({
      ...state,
      disabled: true,
      authenticity_token: document.querySelector('[name=csrf-token]').content,
    });

    let formName = 'Car Enquiry';
    if (query.isVan) {
      formName = 'Van Enquiry';
    }
    if (query.isShortTerm) {
      formName = 'Short Term';
    }

    pushData({
      form_name: formName,
      lease_type: isPersonal ? 'Personal' : 'Business',
      signup: !getValues('contact_opt_out'),
      value: query.monthlyPrice,
      item: {
        item_name: query.model,
        item_brand: query.make,
        item_variant: query.trim,
        item_category: query.isVan ? 'Van' : 'Car',
        item_category2: query.transmission,
        item_category3: query.fueltype,
        price: query.price,
        duration: query.contractLength,
        annual_miles: query.annualMileage,
        initial_payment_duration: query.initialMonths,
        initial_payment_price: query.initialPaymentPrice,
        maintenance: query.maintenanceSelected,
        maintenance_price:
          query.maintainedMonthlyPrice && Math.max(0, query.maintainedMonthlyPrice - query.monthlyPrice),
      },
      user_data: {
        email: getValues('contact_email'),
        phone_number: getValues('contact_telephone'),
      },
    });

    setTimeout(() => {
      formRef.current.submit();
    }, 1000);
  };

  return (
    <form
      className="c-modal-enquire-now__form bootstrap-form"
      onSubmit={handleSubmit(handleFormSubmission)}
      noValidate
      ref={formRef}
      action={postURL}
      method="post"
    >
      <input type="hidden" name="authenticity_token" value={state.authenticity_token} />
      <input type="hidden" name="channel_id" value={channelID} />

      {!!offerId && <input type="hidden" name="offer_id" value={offerId} />}

      {(!!derivative_id || !offerId) && <input type="hidden" name="derivative_id" value={derivativeId} />}

      {!offerId && <input type="hidden" name="term" value={selectedTerm} />}

      {!offerId && <input type="hidden" name="mileage" value={selectedMileage} />}

      {!!maintenanceSelected && <input type="hidden" name="maintenance_selected" value={maintenanceSelected} />}

      {!!initialPaymentMonths && <input type="hidden" name="initial_payment_months" value={initialPaymentMonths} />}

      <div className={`geotargetlygeocontent${geotargetly}`}>
        <input type="hidden" name="office" />
      </div>

      {selectedOptionIds.length > 0 &&
        selectedOptionIds.map((option) => (
          <input type="hidden" name="selected_option_ids[]" value={option} key={option} />
        ))}

      {pricing && <input type="hidden" name="pricing" value={pricing} />}

      {featured_deal && <input type="hidden" name="featured_deal" value={featured_deal} />}

      <div className="b-form-row">
        <FormField
          labelText="First name"
          placeholder="First name"
          id="first_name"
          type="text"
          onChange={(event) => setState({ ...state, firstName: event.target.value })}
          ref={register({ required: true })}
          hasError={!!errors.first_name}
          value={state.firstName}
          errorMessage="Please enter your first name"
        />
        <FormField
          labelText="Last name"
          placeholder="Last name"
          id="last_name"
          type="text"
          onChange={(event) => setState({ ...state, lastName: event.target.value })}
          ref={register({ required: true })}
          hasError={!!errors.last_name}
          value={state.lastName}
          errorMessage="Please enter your last name"
        />
        {!isPersonal && (
          <>
            <FormField
              labelText="Business name"
              placeholder="Business name"
              id="company_name"
              type="text"
              onChange={(event) => setState({ ...state, businessName: event.target.value })}
              ref={register({ required: true })}
              hasError={!!errors.company_name}
              value={state.businessName}
              errorMessage="Please enter your business name"
            />
            <FormField
              labelText="Business Type"
              id="contact_business_type"
              onChange={(event) => setState({ ...state, businessType: event.target.value })}
              ref={register({ required: true })}
              hasError={!!errors.contact_business_type}
              value={state.businessType}
              variant="select"
              options={[
                { value: '', text: 'Business type' },
                { value: 'Limited Company', text: 'Limited Company' },
                { value: 'Sole Trader', text: 'Sole Trader' },
                {
                  value: 'Partnership Four Partners',
                  text: 'Partnership (4+ partners)',
                },
                {
                  value: 'Partnership Two Three Partners',
                  text: 'Partnership (2-3 partners)',
                },
                {
                  value: 'Limited Liability Partnership',
                  text: 'Limited Liability Partnership',
                },
                { value: 'PLC', text: 'PLC' },
              ]}
              errorMessage="Please select your business type."
            />
            <FormField
              labelText="Fleet size"
              id="contact_fleet_size"
              onChange={(event) => setState({ ...state, fleetSize: event.target.value })}
              ref={register({ required: true })}
              hasError={!!errors.contact_fleet_size}
              value={state.fleetSize}
              variant="select"
              options={[
                { value: '', text: 'Fleet size' },
                { value: '1to9', text: '1-9' },
                { value: '10to49', text: '10-49' },
                { value: '50', text: '50+' },
              ]}
              errorMessage="Please select your fleet size."
            />
          </>
        )}
        <FormField
          labelText="Email"
          placeholder="Email"
          id="contact_email"
          type="text"
          onChange={(event) => setState({ ...state, email: event.target.value })}
          ref={register({
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          hasError={!!errors.contact_email}
          value={state.email}
          errorMessage="Please enter a valid email address."
        />
        <FormField
          labelText="Telephone"
          placeholder="Telephone"
          id="contact_telephone"
          type="text"
          onChange={(event) => setState({ ...state, telephone: event.target.value })}
          ref={register({
            required: true,
            validate: (value) => isValidNumber(value, 'GB'),
          })}
          hasError={!!errors.contact_telephone}
          value={state.telephone}
          errorMessage="Please enter a valid telephone number."
        />
        {isPersonal && (
          <FormField
            labelText="Preferred contact time"
            id="contact_time"
            onChange={(event) => setState({ ...state, time: event.target.value })}
            ref={register({ required: false })}
            hasError={!!errors.contact_time}
            value={state.time}
            variant="select"
            options={[
              { value: '', text: 'Contact time' },
              { value: 'Any', text: 'Any' },
              { value: 'Morning', text: 'Morning' },
              { value: 'Afternoon', text: 'Afternoon' },
              { value: 'After 6pm', text: 'After 6pm' },
            ]}
            errorMessage="Please select your preferred contact time."
          />
        )}
        {salesAdmin && (
          <FormField
            labelText="Lead type"
            id="lead_type"
            onChange={(event) => setState({ ...state, leadType: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.lead_type}
            value={state.leadType}
            variant="select"
            options={[
              { value: '', text: 'Select lead type' },
              { value: 'Incoming Call', text: 'Incoming Call' },
              { value: 'Commversion Cold', text: 'Commversion Cold' },
              { value: 'Commversion Warm', text: 'Commversion Warm' },
            ]}
            errorMessage="Please select lead type."
          />
        )}
        {salesAdmin && (
          <FormField
            labelText="Select source"
            id="contact_source"
            onChange={(event) => setState({ ...state, source: event.target.value })}
            ref={register({ required: false })}
            hasError={!!errors.contact_source}
            value={state.source}
            variant="select"
            options={[
              { value: '', text: 'Select source' },
              ...(isPersonal ? personal_sources : business_sources).map((source) => ({ value: source, text: source })),
            ]}
            errorMessage="Please select a source."
          />
        )}
        <FormField
          labelText="Comments"
          id="contact_comments"
          placeholder="Comments"
          onChange={(event) => setState({ ...state, comments: event.target.value })}
          value={state.comments}
        />
      </div>
      <div className="b-form-row">
        <div className="col-12">
          <div className="c-modal-enquire-now__action">
            <button className="b-btn b-btn-shadowless b-btn-green" type="submit" disabled={state.disabled}>
              {state.disabled ? 'Sending...' : 'Send my enquiry'}
            </button>
          </div>
        </div>
      </div>

      <OptOut on_dark ref={register({ required: false })} />
    </form>
  );
};

const enquireNowPropTypes = {
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
  isPersonal: PropTypes.bool.isRequired,
  geotargetly: PropTypes.string,
  pricing: PropTypes.string,
  personal_sources: PropTypes.arrayOf(PropTypes.string),
  business_sources: PropTypes.arrayOf(PropTypes.string),
  featured_deal: PropTypes.string,
  offer_id: PropTypes.string,
  derivative_id: PropTypes.string,
  initial_payment_months: PropTypes.string,
  term: PropTypes.number,
  mileage: PropTypes.number,
  salesAdmin: PropTypes.bool,
};

EnquireNow.propTypes = enquireNowPropTypes;

export default function EnquireNowWrapper({
  postURL,
  channelID,
  isPersonal,
  geotargetly,
  pricing,
  personal_sources,
  business_sources,
  featured_deal,
  offer_id,
  derivative_id,
  initial_payment_months,
  term,
  mileage,
  salesAdmin,
}) {
  return (
    <Provider store={store}>
      <EnquireNow
        postURL={postURL}
        channelID={channelID}
        isPersonal={isPersonal}
        geotargetly={geotargetly}
        pricing={pricing}
        personal_sources={personal_sources}
        business_sources={business_sources}
        featured_deal={featured_deal}
        offer_id={offer_id}
        derivative_id={derivative_id}
        initial_payment_months={initial_payment_months}
        term={term}
        mileage={mileage}
        salesAdmin={salesAdmin}
      />
    </Provider>
  );
}

EnquireNowWrapper.propTypes = enquireNowPropTypes;
